import React, { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { format, formatDistanceToNowStrict } from 'date-fns';
import MuiLink from '@material-ui/core/Link';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import s from 'underscore.string';
import styled from 'styled-components';

import { IListing } from 'src/interface';
import FavouriteListingToggle from '../ListingDetails/Components/FavoutieListingToggle';

const ListingCardContainer = styled.div`
  position: relative;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  &:hover {
    /* box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2); */
  }
`;
const FeaturedTag = styled.div`
  position: absolute;
  top: 15px;
  left: -5px;
`;
const FeaturedIcon = styled.div`
  margin-bottom: 10px;
  background: #f91942;
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  padding: 4px 3px 5px 10px;
  text-transform: uppercase;
  &:before {
    border-left: 10px solid #f91942;
    top: 0;
    left: 100%;
    border-top: 9px solid transparent;
    border-bottom: 10px solid transparent;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
  }
  &:after {
    border-top: 5px solid #993538;
    top: 100%;
    left: 0;
    border-left: 5px solid transparent;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
  }
`;
const ImageSection = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  /* margin: 5px; */
  cursor: pointer;
  img {
    border-radius: 5px;
    /* object-fit: cover; */
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
`;
const TopAdvert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  color: #aaa;
`;
const TopAdvertItem = styled.div`
  max-width: calc(100% - 5px);
  padding-left: 0px;
  margin-left: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 5px;
  }
`;
const AddressContainer = styled(TopAdvertItem)`
  flex-basis: 60%;
`;
const Text = styled.span`
  font-size: 12px;
  color: #aaa;
  outline: none;
  text-decoration: none;
`;
const AdTitle = styled.h5`
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  line-height: 1.3;
  a {
    color: #333;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    transition: all 0.1s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const BottomAdvert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Price = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;
const PrePriceTag = styled.span`
  margin-right: 2px;
`;
const PostPriceTag = styled.span`
  text-transform: capitalize;
  color: #aaa;
  opacity: 1;
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
`;
const CreatedDate = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex-basis: 40%;
  font-size: 10px;
  //align-self: flex-end;
  //margin-top: 5px;
  margin: 0;
  text-align: right;
`;
const LocationText = styled(Text)`
  flex-basis: 100%;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.secondary} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CircleIcon = styled(BlurCircularOutlinedIcon)`
  margin-right: 5px;
`;
const MarkerIcon = styled(RoomOutlinedIcon)`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.secondary} !important;
  margin-right: 5px;
  position: relative;
  top: -1px;
`;

interface IProps {
  listingData: IListing;
  isWantedListing?: boolean;
  className?: string;
}
const ListingCard = ({ listingData, className, isWantedListing }: IProps) => {
  const {
    images,
    medias,
    parent_category,
    child_category,
    title,
    attributes,
    uuid,
    location,
    is_featured,
    created_at,
  } = listingData;

  const parseDate = useMemo(() => {
    const dateString = formatDistanceToNowStrict(new Date(created_at || ''));
    if (dateString.includes('months')) {
      return format(new Date(created_at || ''), 'dd MMM').toUpperCase();
    }
    return dateString.toUpperCase();
  }, [created_at]);

  const { rental_value_per_period, rent_per } = attributes;

  const DetailsHref =
    (isWantedListing && `/wanted-listing/[listingId]`) ||
    `/[parentCategory]/[childCategory]/[listingId]`;
  const DetailsAsPath =
    (isWantedListing && `/wanted-listing/${uuid}`) ||
    `/${parent_category.slug}/${child_category.slug}/${uuid}`;

  const ImageUrl = images[0];

  return (
    <ListingCardContainer className={className}>
      {is_featured && (
        <FeaturedTag>
          <FeaturedIcon>Featured</FeaturedIcon>
        </FeaturedTag>
      )}
      <Link href={DetailsHref} as={DetailsAsPath} passHref prefetch={false}>
        <a href="DetailsAsPath">
          <ImageSection>
            {/* <Overlay /> */}
            <Image
              // width={300}
              // height={300}
              // placeholder="/assets/graphics/placeholderImage.webp"
              src={ImageUrl}
              alt={title}
              layout="fill"
              objectFit="cover"
              objectPosition="center center"
              quality="30"
            />
            {/* <noscript>
              <img src={ImageUrl} alt={title} />
            </noscript> */}
          </ImageSection>
        </a>
      </Link>
      <Content>
        <TopAdvert>
          <TopAdvertItem>
            <CircleIcon />
            {parent_category && (
              <Link
                href="/[parentCategory]"
                as={`/${parent_category.slug}`}
                passHref
                prefetch={false}
              >
                <MuiLink href="/[parentCategory]" target="_blank" rel="noopener" underline="hover">
                  <Text>{parent_category.name}</Text>
                </MuiLink>
              </Link>
            )}
          </TopAdvertItem>
        </TopAdvert>
        <AdTitle>
          <Link href={DetailsHref} as={DetailsAsPath} passHref prefetch={false}>
            <MuiLink href="#">{title}</MuiLink>
          </Link>
        </AdTitle>
        <BottomAdvert>
          <Price>
            <PrePriceTag>RS</PrePriceTag>
            {s.numberFormat(rental_value_per_period, undefined, '.', ',')}
            <PostPriceTag>/ {rent_per || 'day'}</PostPriceTag>
          </Price>
          <FavouriteListingToggle
            listing={listingData}
            iconSize="small"
            isWantedListing={isWantedListing}
          />
        </BottomAdvert>
        <Bottom>
          <AddressContainer>
            <MarkerIcon />
            <LocationText>{location.address.toUpperCase()}</LocationText>
          </AddressContainer>
          <CreatedDate>{parseDate}</CreatedDate>
        </Bottom>
      </Content>
    </ListingCardContainer>
  );
};
export default ListingCard;
