import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import { IListing } from 'src/interface';
import ListingsApis from 'src/api/listings';
import { useAuthentication } from 'src/contexts/auth/auth.context';
import { useNotifier } from 'src/contexts/notification/notification.context';

type TProps = {
  listing: IListing;
  iconSize?: 'small' | 'large' | 'inherit';
  isWantedListing?: boolean;
};
const FavouriteListingToggle = ({ listing, iconSize, isWantedListing }: TProps) => {
  const [isFav, setFav] = useState(listing.favourite);
  const { errorAlert, successAlert } = useNotifier();
  const [authState, dispatchToAuth] = useAuthentication();
  const { isAuthenticated, auth } = authState;

  const handleFavourite = useCallback(async () => {
    if (isAuthenticated) {
      try {
        setFav(!isFav);
        const result = await ListingsApis.setFavouriteListing({
          token: auth?.token || '',
          id: listing.id,
          isFav: listing.favourite,
          isWantedListing,
        });
        if (result.status === 200) {
          successAlert(result.data.message);
        }
        errorAlert(result.data.message);
        setFav(!isFav);
      } catch (error) {
        errorAlert('Something went wrong');
        setFav(!isFav);
      }
    } else {
      dispatchToAuth({ type: 'SWITCH_AUTH_MODAL', payload: true });
    }
  }, [
    isWantedListing,
    isAuthenticated,
    listing.favourite,
    isFav,
    listing.id,
    auth,
    errorAlert,
    successAlert,
    dispatchToAuth,
  ]);

  return (
    <IconButton aria-label="favourite" onClick={handleFavourite}>
      {(isFav && <FavoriteOutlinedIcon fontSize={iconSize} color="primary" />) || (
        <FavoriteBorderOutlinedIcon fontSize={iconSize} color="primary" />
      )}
    </IconButton>
  );
};

export default FavouriteListingToggle;
